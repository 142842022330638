import { Box, Heading, Text, useColorMode, VStack, Flex } from '@chakra-ui/react';
import Footer from '../components/generic/Footer';
import { Helmet } from 'react-helmet';

export const LegalTerms = () => {
  const { colorMode } = useColorMode();

  const bgColor = { light: 'gray.50', dark: 'gray.800' };
  const color = { light: 'black', dark: 'white' };

  return (
    <Box backgroundColor={bgColor[colorMode]} color={color[colorMode]}>
      <Helmet>
        <title>Privacy Policy | Parcelsync</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Flex direction="column" minHeight="95vh" maxWidth="600px" color={color[colorMode]} ml="auto" mr="auto">
        <VStack flexGrow={1} justifyContent="center" alignItems="center" p={4}>
          <Heading>Terms & Conditions</Heading>
          <Heading size="md">1. Introduction</Heading>
          <Text textAlign="center">
            Welcome to Parcelsync. These Terms & Conditions govern your use of our website located at parcelsync.io and
            our tracking service hosted at track.parcelsync.io. By accessing our service, you agree to be bound by these
            Terms & Conditions.
          </Text>
          <Heading size="md">2. Use of Service</Heading>
          <Text textAlign="center">
            Parcelsync provides a parcel tracking service for your shipments. Sender can upload data about parcels, and
            customers can track shipments via our public tracking page.
          </Text>
          <Heading size="md">3. User Responsibilities</Heading>
          <Text textAlign="center">
            Users are expected to use Parcelsync responsibly and legally. Misuse of the service or any fraudulent
            activity will result in termination of access to Parcelsync.
          </Text>
          <Heading size="md">4. Data Protection</Heading>
          <Text textAlign="center">
            We are committed to protecting your privacy. Our Privacy Policy, detailed below, outlines how we handle data
            collected through our service.
          </Text>
          <Heading size="md">5. Limitation of Liability</Heading>
          <Text textAlign="center">
            Parcelsync will not be liable for any indirect, special, or consequential damages arising out of or in
            connection with the use of our service.
          </Text>
          <Heading size="md">6. Contact Us</Heading>
          <Text textAlign="center">
            For any questions or concerns regarding our service or these Terms, please contact us at
            support@mail.parcelsync.io.
          </Text>
        </VStack>
      </Flex>
      <Footer />
    </Box>
  );
};
