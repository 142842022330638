export enum ParcelStatusTypeEnum {
  EXCEPTION = 'EXCEPTION',
  DATA_SENT = 'DATA_SENT',
  ACCEPTED_BY_CARRIER = 'ACCEPTED_BY_CARRIER',
  HANDED_OVER = 'HANDED_OVER',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  STORED_FOR_PICKUP = 'STORED_FOR_PICKUP',
  PICKED_UP = 'PICKED_UP',
  DELIVERED = 'DELIVERED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  RETURNED = 'RETURNED',
  CANCELED = 'CANCELED',
}

export type Parcel = {
  id?: string;
  weight: number;
  width: number;
  height: number;
  length: number;
  referenceNumber: string;
  trackingNumber?: string;
  statuses?: ParcelStatus[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ParcelStatusType = {
  type: ParcelStatusTypeEnum;
  title: { [key: string]: string };
  description: { [key: string]: string };
  icon: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ParcelStatus = {
  type: string;
  statusObj: ParcelStatusType;
  metadata?: { [key: string]: string | string[] } | undefined;
  createdAt?: Date;
  updatedAt?: Date;
};
