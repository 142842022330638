import React, { useContext, useState } from 'react';
import { createContext } from 'react';
import { Shipment } from '../types/shipment';
// import { useAsyncError } from '../hooks';
import { useApiActions } from '../hooks/api-actions';
import { LoadingPage } from '../pages';
import { ShipmentAnalyticsEventJSONData, ShipmentAnalyticsEventType } from '../types/shipment-analytics-events';

interface ShipmentsProviderProps {
  selectedShipment: Shipment | null;
  fetchShipment: (id: string) => Promise<void>;
  sendAnalyticsEvent: (event: ShipmentAnalyticsEventType, data: ShipmentAnalyticsEventJSONData) => void;
}

interface ShipmentResponse {
  shipment: Shipment;
}

const ShipmentsContext = createContext<ShipmentsProviderProps | null>(null);

export const ShipmentsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { executeApiAction } = useApiActions();
  // const throwError = useAsyncError();

  const [loading, setLoading] = useState(false);
  const [selectedShipment, setSelectedShipmentState] = useState<Shipment | null>(null);

  const fetchShipment = async (id: string) => {
    const res = await executeApiAction<Shipment>({
      action: async ({ publicClient }) =>
        (
          await publicClient.get(`tracking/shipment/${id}`).json<ShipmentResponse>()
        ).shipment,
      onSuccess: () => {
        setLoading(false);
      },
      onError: (error: any) => {
        // throwError(error);
        console.warn('Error fetching shipment', error);
      },
    });
    setSelectedShipmentState(res || null);
  };

  const sendAnalyticsEvent = (event: ShipmentAnalyticsEventType, data: ShipmentAnalyticsEventJSONData) => {
    if (!selectedShipment) return;
    executeApiAction({
      action: async ({ publicClient }) => {
        await publicClient.post(`shipment/${selectedShipment?.id}/analytics/event`, {
          json: {
            eventType: event,
            eventData: data,
          },
        });
      },
      onError: (error: any) => {
        console.warn('Error sending analytics event', error);
      },
      silent: true,
    });
  };

  return (
    <ShipmentsContext.Provider
      value={{
        selectedShipment,
        fetchShipment,
        sendAnalyticsEvent,
      }}
    >
      {loading && <LoadingPage />}
      {children}
    </ShipmentsContext.Provider>
  );
};

export const useShipmentsContext = (): ShipmentsProviderProps => {
  const context = useContext(ShipmentsContext);

  if (!context) {
    throw new Error(
      'Failed to load shipments context. Make sure you are consuming the context within a provider block'
    );
  }

  return context;
};
