import { useMemo, useCallback } from 'react';
import { Box, Text, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Shipment } from '../../../types/shipment';
import { ShipmentAnalyticsEventType } from '../../../types/shipment-analytics-events';
import { useShipmentsContext } from '../../../providers';
import { useTranslation } from 'react-i18next';

const TrackingNumber = ({ shipment }: { shipment: Shipment }) => {
  const { t } = useTranslation('tracking');
  const { sendAnalyticsEvent } = useShipmentsContext();
  const carrierLogo = useMemo(() => {
    switch (shipment?.carrier) {
      case 'PACKETA':
        return '/images/carriers/packeta.png';
      case 'PPL':
        return '/images/carriers/ppl.png';
      case 'CESKAPOSTA':
        return '/images/carriers/ceska-posta.png';
      default:
        return '';
    }
  }, [shipment?.carrier]);

  const trackingLink = useCallback(
    (trackingNumber: string) => {
      switch (shipment?.carrier) {
        case 'PACKETA':
          return `https://tracking.app.packeta.com/cs/${trackingNumber}`;
        case 'PPL':
          return `https://www.ppl.cz/vyhledat-zasilku?shipmentId=${trackingNumber}`;
        case 'CESKAPOSTA':
          return `https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers=${trackingNumber}`;
        default:
          return '';
      }
    },
    [shipment?.carrier]
  );

  const deviceType = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={20} px={2} py={2}>
        <Image src={carrierLogo} height="30px" />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
        <Text fontSize="ml" as="b">
          {t('shipping-numbers-title', { count: shipment?.parcels?.length })}
        </Text>
        {shipment?.parcels?.map((p) => {
          if (!p.trackingNumber) {
            return null;
          }
          return (
            <Link
              key={p.id}
              href={trackingLink(p.trackingNumber)}
              isExternal
              onClick={() => {
                const path = window.location.pathname;
                sendAnalyticsEvent(ShipmentAnalyticsEventType.CLICK, {
                  url: trackingLink(p.trackingNumber as string),
                  location: path,
                  device: deviceType,
                });
              }}
            >
              {p.trackingNumber} <ExternalLinkIcon mx="2px" />
            </Link>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
        <Text fontSize="ml" as="b">
          {t('parcels-title', { count: shipment?.parcels?.length })}
        </Text>
        <Text fontSize="ml">{shipment?.parcels?.length}</Text>
      </Box>
    </>
  );
};

export default TrackingNumber;
