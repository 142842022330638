import { Box, Image, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useShipmentsContext } from '../../providers';
import { ShipmentAnalyticsEventType } from '../../types/shipment-analytics-events';

const AdBanner = ({ src, link }: { src?: string; link?: string }) => {
  const deviceType = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });
  const { sendAnalyticsEvent } = useShipmentsContext();

  if (!src) return null;

  const content = <Image height="auto" width="100%" maxWidth="550px" ml="auto" mr="auto" src={src} />;
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="80%" ml="auto" mr="auto" pt={4}>
      {link ? (
        <Link
          to={link}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            const path = window.location.pathname;
            sendAnalyticsEvent(ShipmentAnalyticsEventType.CLICK, {
              url: link,
              location: path,
              device: deviceType,
            });
          }}
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </Box>
  );
};

export default AdBanner;
