import { motion } from 'framer-motion';
import { Box, Text, VStack, Circle, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Stepper = ({ steps, primaryColor }: { steps: { title: string; date: string }[]; primaryColor: string }) => {
  const { t } = useTranslation('tracking');
  return (
    <VStack align="stretch" spacing={4}>
      <Text fontSize="xl" fontWeight="bold">
        {t('tracking-steps-title')}
      </Text>
      {steps.map((step, index) => (
        <Box key={index}>
          <HStack>
            <Box position="relative">
              <motion.div
                initial={{ scale: 1 }}
                animate={index === steps.length - 1 ? { scale: 1.3 } : {}}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 1,
                }}
              >
                <Circle
                  size="12px"
                  bg={primaryColor}
                  opacity={index === steps.length - 1 ? 1 : 0.2}
                  alignContent="center"
                  border={4}
                ></Circle>
              </motion.div>
              {index < steps.length - 1 && (
                <Box
                  width="1px"
                  height="35px"
                  bg="gray.200"
                  position="absolute"
                  left="50%"
                  top="20px"
                  transform="translateX(-50%)"
                  borderStyle="dashed"
                />
              )}
            </Box>
            <VStack align="start" spacing={0} ml={10}>
              <Text fontWeight="bold">{step.title}</Text>
              <Text fontSize="sm">{step.date}</Text>
            </VStack>
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default Stepper;
