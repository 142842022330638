import { Shipper } from './shipper';
import { Recipient } from './recipient';
import { Parcel } from './parcel';
import { Payment } from './payment';
import { Insurance } from './insurance';
import { Customs } from './customs';
import { ProjectSeller } from '../project-seller';

export enum ShipmentStatus {
  Imported = 'IMPORTED',
  Sent = 'SENT',
  Error = 'ERROR',
  Finished = 'FINISHED',
}

export type Shipment = {
  id: string;
  projectId: string;
  carrier: string;
  carrierService: string;
  seller?: ProjectSeller;
  reference: string;
  note?: string;
  shipper: Shipper;
  recipient: Recipient;
  parcels: Parcel[];
  payment?: Payment;
  insurance?: Insurance;
  customs?: Customs;
  carrierOptions: { [key: string]: string | string[] } | undefined;
  status: ShipmentStatus;
  createdAt: Date;
  updatedAt: Date;
};
