import { Box, Badge, Text } from '@chakra-ui/react';
import { Payment as TPayment } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';

const Payment = ({ payment }: { payment: TPayment }) => {
  const { t } = useTranslation('tracking');
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
      <Text fontSize="ml" as="b">
        {payment?.type === 'cashondelivery' ? t('cod-title') : t('prepaid-title')}
      </Text>
      {payment?.type === 'cashondelivery' ? (
        <Badge>
          {payment?.amount} {payment?.currency}
        </Badge>
      ) : null}
    </Box>
  );
};

export default Payment;
