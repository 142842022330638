import { Box, Image, useColorModeValue } from '@chakra-ui/react';
import DonutFactory, { DonutState } from './DonutCircle';
import Stepper from './Stepper';
import dayjs from 'dayjs';
import ContactSocial from './ContactSocial';
import AdBanner from './AdBanner';
import Address from './info/Address';
import Payment from './info/Payment';
import TrackingNumber from './info/TrackingNumber';
import { ProjectSeller } from '../../types/project-seller';
import { Shipment } from '../../types/shipment';

import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Prague');

const TrackingPageData = ({
  shipment,
  donutStatus,
  seller,
}: {
  shipment: Shipment;
  seller?: ProjectSeller;
  donutStatus: DonutState;
}) => {
  const bgColor = useColorModeValue('#FFFFFF', '#000000');
  const textColor = useColorModeValue('#000000', '#FFFFFF');
  return (
    <div
      style={{
        width: `100%`,
        height: `auto`,
        backgroundColor: bgColor,
      }}
    >
      <Box backgroundColor="white" zIndex={4} position={`relative`}>
        <Box
          backgroundImage={seller?.customLayout?.backgroundImage || '/default/bg.jpg'}
          backgroundSize="cover"
          backgroundPosition="center"
          zIndex={-12}
          height="250px"
          position="relative"
          top="0"
          width="100%"
        >
          <Box
            height="100px"
            width="150px"
            ml="auto"
            mr="auto"
            mt="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position={`absolute`}
            left={0}
            right={0}
            marginLeft={`auto`}
            marginRight={`auto`}
            zIndex={2}
          >
            {seller?.customLayout?.logo_dark && (
              <Image height="80px" width="100%" ml="auto" mr="auto" src={seller?.customLayout?.logo_dark} />
            )}
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="rgba(0, 0, 0, 0.65)"
            zIndex={1}
          />
        </Box>
        <Box
          zIndex={-10}
          position="relative"
          width="100%"
          height="250px"
          backgroundColor={bgColor}
          borderRadius="30px 30px 0 0"
          marginTop={`-25px`}
        >
          <DonutFactory state={donutStatus} offsetTop={-100} />
        </Box>
      </Box>
      <Box backgroundColor={bgColor} color={textColor} maxWidth="550px" width="80%" ml="auto" mr="auto">
        <TrackingNumber shipment={shipment} />
        {shipment?.payment && <Payment payment={shipment.payment} />}
        {shipment?.recipient && <Address recipient={shipment.recipient} />}
      </Box>
      <AdBanner src={seller?.customLayout?.banner || undefined} link={seller?.customLayout?.bannerLink} />
      {shipment?.parcels?.map((parcel) => (
        <Box
          key={parcel.id}
          backgroundColor={bgColor}
          color={textColor}
          pt={10}
          maxWidth="550px"
          width="80%"
          ml="auto"
          mr="auto"
          pb={4}
        >
          <Stepper
            primaryColor={`#${seller?.customLayout?.primaryColor}` || '#206390'}
            steps={
              parcel?.statuses?.map((status) => {
                return {
                  title: status?.statusObj.title[seller?.language || 'cs'],
                  date: dayjs(status?.createdAt).tz().format('DD. MM. YYYY, HH:mm'),
                };
              }) || []
            }
          />
        </Box>
      ))}
      {(seller?.customLayout?.contactInfo || seller?.customLayout?.socialMedia) && <ContactSocial seller={seller} />}
    </div>
  );
};

export default TrackingPageData;
