import { Box, Heading, Text, useColorMode, VStack, Flex } from '@chakra-ui/react';
import Footer from '../components/generic/Footer';
import { Helmet } from 'react-helmet';

export const LegalPrivacy = () => {
  const { colorMode } = useColorMode();

  const bgColor = { light: 'gray.50', dark: 'gray.800' };
  const color = { light: 'black', dark: 'white' };

  return (
    <Box backgroundColor={bgColor[colorMode]} color={color[colorMode]}>
      <Helmet>
        <title>Privacy Policy | Parcelsync</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Flex direction="column" minHeight="95vh" maxWidth="600px" color={color[colorMode]} ml="auto" mr="auto">
        <VStack flexGrow={1} justifyContent="center" alignItems="center" p={4}>
          <Heading>Privacy Policy</Heading>
          <Heading size="md">1. Introduction</Heading>
          <Text textAlign="center">
            Parcelsync ("we", "us", or "our") operates the websites parcelsync.io and its subdomains, including
            track.parcelsync.io (collectively referred to as the "Service"). This Privacy Policy informs you of our
            policies regarding the collection, use, and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </Text>
          <Heading size="md">2. Information Collection and Use</Heading>
          <Text textAlign="center">
            We collect several types of information for various purposes to provide and enhance our Service to you.
            <br />
            Types of Data Collected:
            <br />
            <ul>
              <li>
                Personal Data: While using our Service, we may ask you to provide us with certain personally
                identifiable information that can be used to contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to, email addresses and phone numbers.
              </li>
              <li>
                Usage Data: We may also collect information on how the Service is accessed and used ("Usage Data"). This
                Usage Data may include details such as your computer's Internet Protocol address (e.g., IP address),
                browser
              </li>
              type, browser version, our Service pages that you visit, the time and date of your visit, the time spent
              on those pages, and other diagnostic data.
              <li>
                Tracking & Cookies Data: We use cookies and similar tracking technologies to track the activity on our
                Service and we hold certain information. Cookies are files with a small amount of data which may include
                an anonymous unique identifier.
              </li>
            </ul>
          </Text>
          <Heading size="md">3. Use of Data</Heading>
          <Text textAlign="center">
            Parcelsync uses the collected data for various purposes:
            <ul>
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
              <li>To provide customer support</li>
              <li>To gather analysis or valuable information so that we can improve our Service</li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent, and address technical issues</li>
            </ul>
          </Text>
          <Heading size="md">4. Data Sharing and Disclosure</Heading>
          <Text textAlign="center">
            We may disclose your Personal Data in the good faith belief that such action is necessary to:
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Parcelsync</li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>To protect the personal safety of users of the Service or the public</li>
              <li>To protect against legal liability</li>
            </ul>
          </Text>
        </VStack>
      </Flex>
      <Footer />
    </Box>
  );
};
