import { Box, Image, Text, Flex } from '@chakra-ui/react';
import { ThemeSwitcher } from '../../theme/theme-switcher';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = ({ allowDarkMode = true }: { allowDarkMode?: boolean }) => {
  const { t } = useTranslation('footer');
  return (
    <Box
      backgroundColor="black"
      color="white"
      pt={4}
      width="100%"
      ml="auto"
      mr="auto"
      pb={4}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        ml={4}
        pb={4}
        style={{
          borderBottom: '1px solid #454545',
        }}
      >
        {/* Left aligned items */}
        <Flex>
          <Link to={'/legal/terms'}>
            <Text>{t('terms-conditions')}</Text>
          </Link>
          <Link to={'/legal/privacy'}>
            <Text ml={4}>{t('privacy-policies')}</Text>
          </Link>
        </Flex>

        {/* Right aligned items with consistent vertical alignment */}
        <Flex alignItems="center">{allowDarkMode && <ThemeSwitcher />}</Flex>
      </Flex>

      <Flex textAlign="center" justifyContent="center">
        <Image src={'/parcelsync-light.svg'} alt="Logo" width="125px" height="auto" />
      </Flex>
    </Box>
  );
};

export default Footer;
