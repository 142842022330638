import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { TrackingPage, NotFoundPage, LegalTerms, LegalPrivacy } from './pages';
import { theme } from './theme';
import { ShipmentsProvider, LoadingProvider, AppConfigProvider } from './providers';
import { ErrorPage } from './pages/error-page';

export const App = () => {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <ErrorBoundary fallback={<ErrorPage />}>
          <LoadingProvider>
            <AppConfigProvider>
              <ColorModeScript initialColorMode={theme.config.initialColorMode} type="localStorage" />
              <Routes>
                <Route
                  path="/:shipmentId"
                  element={
                    <ShipmentsProvider>
                      <TrackingPage />
                    </ShipmentsProvider>
                  }
                />

                <Route path="/legal/terms" element={<LegalTerms />} />
                <Route path="/legal/privacy" element={<LegalPrivacy />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </AppConfigProvider>
          </LoadingProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </Router>
  );
};
