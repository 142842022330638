import { DefaultLayout } from '../layouts';

import { useParams } from 'react-router-dom';
import { useShipmentsContext } from '../providers';
import { useEffect, useMemo, useState } from 'react';
import { LoadingPage } from '../pages';
import { DonutState, DonutStateMap } from '../components/tracking/DonutCircle';
import { Helmet } from 'react-helmet';
import Footer from '../components/generic/Footer';
import { ProjectSeller } from '../types/project-seller';
import TrackingPageData from '../components/tracking/TrackingPageData';
import { useColorMode, useBreakpointValue } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { ShipmentAnalyticsEventType } from '../types/shipment-analytics-events';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

export const TrackingPage = () => {
  const { shipmentId } = useParams();
  const { selectedShipment, fetchShipment, sendAnalyticsEvent } = useShipmentsContext();
  const [seller, setSeller] = useState<ProjectSeller | undefined>(undefined);
  const { setColorMode } = useColorMode();
  const deviceType = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });
  useEffect(() => {
    if (shipmentId) {
      fetchShipment(shipmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation('tracking');

  useEffect(() => {
    if (selectedShipment && selectedShipment?.seller) {
      const seller = selectedShipment?.seller;
      setSeller(seller);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShipment]);

  useEffect(() => {
    if (selectedShipment?.id && deviceType) {
      sendViewEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, selectedShipment]);

  useEffect(() => {
    // set language for react-i18next based on seller language
    if (seller?.language) {
      i18n.changeLanguage(seller.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  const sendViewEvent = () => {
    const cookie = Cookies.get('tracking-viewed');
    if (!cookie) {
      const pathName = window.location.pathname;
      const inOneHour = new Date().getTime() + 60 * 60 * 1000;
      sendAnalyticsEvent(ShipmentAnalyticsEventType.VIEW, {
        url: window.location.href,
        location: pathName,
        device: deviceType,
      });

      Cookies.set('tracking-viewed', 'true', { expires: inOneHour, path: pathName });
    }
  };

  useEffect(() => {
    if (seller?.customLayout?.allowDarkMode === false) {
      setColorMode('light');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  const donutStatus: DonutState = useMemo(() => {
    if (!selectedShipment) {
      return DonutState.EXCEPTION;
    }
    // if all parcels are delivered (last status is DELIVERED), it's simple
    if (selectedShipment?.parcels?.every((parcel) => parcel?.statuses?.[0]?.statusObj?.type === 'DELIVERED')) {
      console.log('all parcels delivered');
      return DonutState.DELIVERED;
    }

    // if not, return the first parcel that is not delivered
    const parcel = selectedShipment?.parcels?.find((parcel) => {
      return parcel?.statuses?.find((status) => {
        return status?.statusObj?.type !== 'DELIVERED';
      });
    });
    const status = parcel?.statuses?.[parcel?.statuses?.length - 1]?.statusObj?.type;
    if (!status) {
      return DonutState.EXCEPTION;
    }

    return DonutStateMap[status];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShipment?.parcels]);

  if (!selectedShipment) {
    return <LoadingPage />;
  }

  const title = `${t('meta-title')} ${(seller?.customLayout?.title && `| ${seller?.customLayout?.title}`) || ''}`;

  return (
    <DefaultLayout displayLoadingBar={false}>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex,nofollow" />
        <meta property="og:title" content={title} />
        <meta name="image" content={seller?.customLayout?.backgroundImage} />
        <meta property="og:image" content={seller?.customLayout?.backgroundImage} />
      </Helmet>
      <TrackingPageData shipment={selectedShipment} donutStatus={donutStatus} seller={seller} />
      <Footer allowDarkMode={seller?.customLayout?.allowDarkMode} />
    </DefaultLayout>
  );
};
