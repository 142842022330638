import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'cs',
    fallbackLng: 'cs',
    load: 'languageOnly',
    debug: false,
    supportedLngs: ['cs', 'sk', 'de', 'en', 'pl'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'tracking',
    ns: ['tracking', 'donut', 'footer'],
  });

export default i18n;
