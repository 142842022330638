import type { Arc } from 'd3-shape';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import type { HierarchyRectangularNode } from 'd3-hierarchy';
import { pie, arc } from 'd3-shape';
import { hierarchy, partition } from 'd3-hierarchy';
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { ParcelStatusTypeEnum } from '../../types/shipment';
import { useTranslation } from 'react-i18next';

const PADDING = 8;
const RADIUS = 100;

export enum DonutState {
  DATA_TRANSFERRED = 'DATA_TRANSFERRED',
  SHIPMENT_PICKED_UP = 'SHIPMENT_PICKED_UP',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERING = 'DELIVERING',
  FOR_PICKUP = 'FOR_PICKUP',
  DELIVERED = 'DELIVERED',
  PICKED_UP = 'PICKED_UP',
  RETURNED = 'RETURNED',
  CANCELED = 'CANCELED',
  EXCEPTION = 'EXCEPTION',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export const DonutStateMap: {
  [key in string]: DonutState;
} = {
  [ParcelStatusTypeEnum.EXCEPTION]: DonutState.EXCEPTION,
  [ParcelStatusTypeEnum.DATA_SENT]: DonutState.DATA_TRANSFERRED,
  [ParcelStatusTypeEnum.ACCEPTED_BY_CARRIER]: DonutState.SHIPMENT_PICKED_UP,
  [ParcelStatusTypeEnum.HANDED_OVER]: DonutState.SHIPMENT_PICKED_UP,
  [ParcelStatusTypeEnum.IN_TRANSIT]: DonutState.IN_TRANSIT,
  [ParcelStatusTypeEnum.OUT_FOR_DELIVERY]: DonutState.DELIVERING,
  [ParcelStatusTypeEnum.STORED_FOR_PICKUP]: DonutState.FOR_PICKUP,
  [ParcelStatusTypeEnum.DELIVERED]: DonutState.DELIVERED,
  [ParcelStatusTypeEnum.DELIVERY_FAILED]: DonutState.DELIVERY_FAILED,
  [ParcelStatusTypeEnum.RETURNED]: DonutState.RETURNED,
  [ParcelStatusTypeEnum.CANCELED]: DonutState.CANCELED,
};

const DonutFactory = ({ state, offsetTop }: { state: DonutState; offsetTop?: number }) => {
  const { t } = useTranslation('donut');
  const DonutSettings: {
    [key: string]: {
      baseColor: string;
      activeColor: string;
      activeSegments: number;
      label: string;
      children?: React.ReactNode;
    };
  } = {
    [DonutState.DATA_TRANSFERRED]: {
      baseColor: '#81B4D6',
      activeColor: '#2980B9',
      activeSegments: 1,
      label: t('donut:data_transferred'), //'Předali jsme data přepravci!',
      children: (
        <motion.image
          href="/images/donut/computer.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            y: [0, 0, 10, 10, 0],
          }}
          transition={{
            duration: 0.75,
            repeat: Infinity,
          }}
        />
      ),
    },
    [DonutState.SHIPMENT_PICKED_UP]: {
      baseColor: '#81B4D6',
      activeColor: '#2980B9',
      activeSegments: 2,
      label: t('donut:shipment_picked_up'), //'Zásilku jsme předali přepravci!',
      children: (
        <motion.image
          href="/images/donut/box.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            y: [0, 0, 10, 10, 0],
          }}
          transition={{
            duration: 0.75,
            repeat: Infinity,
          }}
        />
      ),
    },
    [DonutState.IN_TRANSIT]: {
      baseColor: '#81B4D6',
      activeColor: '#2980B9',
      activeSegments: 3,
      label: t('donut:in_transit'), //'Na cestě!',
      children: (
        <motion.image
          href="/images/donut/truck.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            y: [0, 0, 10, 10, 0],
          }}
          transition={{
            duration: 0.75,
            repeat: Infinity,
          }}
        />
      ),
    },
    [DonutState.DELIVERING]: {
      baseColor: '#81B4D6',
      activeColor: '#2980B9',
      activeSegments: 4,
      label: t('donut:delivering'), //'Zásilka je doručována!',
      children: (
        <motion.image
          href="/images/donut/truck.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            y: [0, 0, 10, 10, 0],
          }}
          transition={{
            duration: 0.75,
            repeat: Infinity,
          }}
        />
      ),
    },
    [DonutState.FOR_PICKUP]: {
      baseColor: '#81B4D6',
      activeColor: '#2980B9',
      activeSegments: 4,
      label: t('donut:for_pickup'), //'Připraveno k vyzvednutí!',
      children: (
        <motion.image
          href="/images/donut/store.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.DELIVERED]: {
      baseColor: '#27AE60',
      activeColor: '#27AE60',
      activeSegments: 5,
      label: t('donut:delivered'), //'Zásilka doručena, děkujeme!',
      children: (
        <motion.image
          href="/images/donut/check.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.PICKED_UP]: {
      baseColor: '#27AE60',
      activeColor: '#27AE60',
      activeSegments: 5,
      label: t('donut:picked_up'), //'Zásilka vyzvednuta, děkujeme!',
      children: (
        <motion.image
          href="/images/donut/check.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.RETURNED]: {
      baseColor: '#c0392b',
      activeColor: '#c0392b',
      activeSegments: 5,
      label: t('donut:returned'), //'Zásilka byla vrácena.',
      children: (
        <motion.image
          href="/images/donut/cross.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.CANCELED]: {
      baseColor: '#e74c3c',
      activeColor: '#c0392b',
      activeSegments: 5,
      label: t('donut:canceled'), //'Zásilka byla zrušena.',
      children: (
        <motion.image
          href="/images/donut/cross.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.EXCEPTION]: {
      baseColor: '#e74c3c',
      activeColor: '#c0392b',
      activeSegments: 0,
      label: t('donut:exception'), //'Došlo k chybě.',
      children: (
        <motion.image
          href="/images/donut/cross.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
    [DonutState.DELIVERY_FAILED]: {
      baseColor: '#e74c3c',
      activeColor: '#c0392b',
      activeSegments: 4,
      label: t('donut:delivery_failed'), //'Zásilku se nepodařilo doručit.',
      children: (
        <motion.image
          href="/images/donut/cross.png"
          x={RADIUS - 30}
          width="60px"
          height="100%"
          animate={{
            // animation that will slightly bounce the image up and down
            rotate: [0, 0, 270, 270, 0],
          }}
        />
      ),
    },
  };
  const { baseColor, activeColor, activeSegments, children, label } = DonutSettings[state];
  const bgColor = useColorModeValue('#FFFFFF', '#000000');
  // create an array of values for the donut
  const values = Array.from(Array(5).keys()).map((i) => ({
    value: 20,
    color: i < activeSegments ? activeColor : baseColor,
  }));
  return (
    <Donut
      values={values}
      radius={RADIUS}
      style={{
        margin: 'auto',
        width: 210,
        height: 210,
        borderRadius: '50%',
        border: `5px solid ${bgColor}`,
        backgroundColor: bgColor,
        zIndex: 10,
        position: `relative`,
        top: `${offsetTop || 0}px`,
      }}
      label={label}
    >
      {children}
    </Donut>
  );
};

interface DonutData {
  value: number;
  color: string;
  children?: Omit<DonutData, 'children'>[];
}

interface DonutProps {
  values: DonutData[];
  radius: number;
  label: string;
  style?: object;
  children?: React.ReactNode;
}

const Donut = ({ values, radius, label, style, children }: DonutProps) => {
  const textColor = useColorModeValue('#000000', '#FFFFFF');

  pie<DonutData>()
    .value((d) => d.value)
    .sortValues((a, b) => a - b)(values);
  const svgRef = useRef(null);
  const descendants = partition<DonutData>()
    .size([2 * Math.PI, radius])(
      hierarchy<DonutData>({
        value: 0,
        color: 'transparent',
        children: values,
      } as DonutData).sum((d) => Math.max(0, d.value ?? 0))
    )
    .descendants();

  const donut: Arc<unknown, HierarchyRectangularNode<DonutData>> = arc<unknown, HierarchyRectangularNode<DonutData>>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, (2 * PADDING) / radius))
    .padRadius(radius / 2)
    .innerRadius((d) => d.y0 + (d.depth < 2 ? radius * 0.35 : 0))
    .outerRadius((d) => d.y1 - PADDING - (d.depth >= 2 ? radius * 0.2 : 0))
    .cornerRadius(Math.max(4, radius / 40));

  return (
    <>
      <div style={style}>
        <svg width={radius * 2} height={radius * 2} ref={svgRef} style={{}}>
          <g transform={`translate(${radius},${radius})`}>
            {descendants.map((d, i) => (
              <path key={i} d={donut(d) ?? ''} fill={d.data.color} />
            ))}
          </g>
          {children}
        </svg>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Text color={textColor} as="b" fontSize="2xl">
          {label}
        </Text>
      </Box>
    </>
  );
};

export default DonutFactory;
