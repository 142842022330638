export type ShipmentAnalyticsEventJSONData = {
  url?: string;
  location?: string;
  device?: string;
};

export enum ShipmentAnalyticsEventType {
  VIEW = 'view',
  CLICK = 'click',
}
