import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import ky, { NormalizedOptions } from 'ky';

import { useLoadingContext } from '../providers';
import { KyInstance } from 'ky/distribution/types/ky';

type ApiErrorInterface = {
  name: string;
  message: string;
  status: number;
};

export type RefreshTokenResponse = {
  accessToken: string;
};

type ExecuteApiCallAction<T> = ({ publicClient }: { publicClient: KyInstance }) => Promise<T>;

export class ApiError extends Error implements ApiErrorInterface {
  public readonly name: string;
  public readonly status: number;

  constructor({ name, message, status }: { name: string; message: string; status: number }) {
    super(message);
    this.name = name;
    this.status = status;
  }
}

export const useApiActions = () => {
  const toast = useToast();
  const { setLoading } = useLoadingContext();
  const errorHook = useCallback(async (_request: Request, _options: NormalizedOptions, response: Response) => {
    if (!response.ok) {
      const body = await response.json();

      if (body && isCustomApiError(body)) {
        throw new ApiError({ name: body.name, message: body.message, status: response.status });
      }

      throw new ApiError({
        name: 'UNKNOWN_ERROR',
        message: 'An unknown error occurred, please try again later',
        status: 500,
      });
    }
  }, []);

  const executeApiCall = useCallback(
    async <T>({ action }: { action: ExecuteApiCallAction<T> }): Promise<T> => {
      const publicClient = ky.create({
        prefixUrl: process.env.REACT_APP_API_URL,
        credentials: 'include',
        mode: 'cors',
      });

      try {
        return action({ publicClient });
      } catch (error: unknown) {
        if (error instanceof ApiError && error.status === 401) {
        }
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorHook]
  );

  const executeApiAction = async <T>({
    action,
    onError,
    onSuccess,
    errorMessage = null,
    successMessage = null,
    errorMessageDuration = 9000,
    silent = false,
  }: {
    action: ExecuteApiCallAction<T>;
    onError?: Function;
    onSuccess?: Function;
    errorMessage?: string | null;
    successMessage?: string | null;
    errorMessageDuration?: number | null;
    silent?: boolean;
  }): Promise<T | null> => {
    setLoading(true);
    try {
      const result = await executeApiCall<T>({ action });
      if (!silent && successMessage) {
        toast({
          title: 'Success',
          description: successMessage,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      if (onSuccess) await onSuccess(result);
      setLoading(false);
      return result;
    } catch (error: any) {
      if (onError) await onError(error);
      if (!silent && error.message) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: errorMessageDuration,
          isClosable: true,
        });
      } else if (!silent && errorMessage) {
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: errorMessageDuration,
          isClosable: true,
        });
      }
    }
    setLoading(false);
    return null;
  };

  const isCustomApiError = (error: Record<string, unknown>) =>
    ['name', 'message'].every((item) => error.hasOwnProperty(item));

  return {
    executeApiAction: useCallback(executeApiAction, [setLoading, toast, executeApiCall]),
  };
};
